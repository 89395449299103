<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import {
  listBankAccount,
  modifyStopStatus,
  delBankAccount,
  modifyBankAccount,
} from "@/api/goods/finance";

export default {
  name: "accountManage",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        type: "FinanceCheck",
        show: false,
      },
      options: {
        mutiSelect: true, // 多选
        loading: true,
        uuid: "d6b2667a-3a91-4fcf-075c-7b2c3eee5380",
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listBankAccount,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            type: "filters",
            tip: "全部/活动方案编号/活动方案名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动方案编号" },
              { filter: "promoNames", label: "活动方案名称" },
            ],
          },

          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          // {
          //   click: "copy",
          //   label: "复制",
          //   icon: "el-icon-document-copy",
          //   type: 'success',
          //   disabled: () => !this.options?.check?.length,
          // },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        operates: {
          width: 80,
          list: [
            {
              text: "编辑",
              method: (row) => {
                let obj = JSON.parse(JSON.stringify(row));
                obj.type = "accountManage";
                this.dialogOptions = {
                  ...this.dialogOptions,
                  show: true,
                  click: "add",
                  data: row,
                  title: "账户管理",
                  formData: {
                    bankAccountId: row.bankAccountId,
                  },
                };
              },
            },
          ],
        },
        columns: [
          {
            prop: "bankAccountNo",
            label: "账户编号",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "bankAccountName",
            label: "账户名称",
            minWidth: 155,
          },
          {
            prop: "bankNo",
            label: "银行账号",
            minWidth: 155,
          },
          {
            prop: "bankTitle",
            label: "开户名",
            minWidth: 155,
          },
          {
            prop: "bankName",
            label: "开户行",
            minWidth: 155,
          },
          {
            prop: "isStop",
            label: "状态",
            minWidth: 155,
            formatter: (v) => (v ? "禁用" : "启用"),
          },
          {
            prop: "isDefault",
            label: "是否默认",
            minWidth: 155,
            formatter: (v) => (v ? "否" : "是"),
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 155,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            // const billId = row.billId
            // let promoType = row.promoType
            // let routerName = ''
            // if (promoType === '0') routerName = 'SpecialPromo'
            // this.$router.push({
            //   name: routerName,
            //   query: {
            //     billId: billId,
            //     type: 'Update'
            //   }
            // })
          }
          break;
        case "add":
          {
            this.dialogOptions = {
              ...this.dialogOptions,
              show: true,
              click: type,
              title: "账户管理",
            };
          }
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData, selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData, selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        // case "copy":
        //   {
        //     if (!selectData.length) return;
        //     this.handleBillStatus("复制", selectData );
        //   }
        //   break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData, url) {
      let name = "";
      let billStatus = "";
      let isStop = "";
      if (command === "删除") {
        name = "删除";
        billStatus = "3";
      } else if (command === "复制") {
        name = "复制";
        billStatus = "4";
      } else if (command === "启用") {
        name = "启用";
        isStop = false;
      } else if (command === "禁用") {
        name = "禁用";
        isStop = true;
      }
      const bankAccountId = selectData.map((i) => i.bankAccountId);
      const $this = this;
      this.$modal.confirm(`是否确认${name}账户编号`).then(function () {
        if (["启用", "禁用"].includes(name)) {
          modifyStopStatus({ bankAccountIds: [...bankAccountId], isStop: isStop }).then(
            (res) => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              if (!isStop) {
                $this.$modal.msgSuccess("启用成功");
              } else {
                $this.$modal.msgSuccess("禁用成功");
              }
            }
          );
        } else {
          delBankAccount({ bankAccountIds: [...bankAccountId] }).then((res) => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
            $this.$modal.msgSuccess("删除成功");
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
